<template>
    <div class="mainform">
        <div class="form">
            <el-form  class="content bascform" ref="addfilelidt" :model="form" :rules="rules" label-width="120px">
            <div class="col col4">
              <el-form-item label="附件类型" prop="fileType">
                  <el-select size="small" filterable v-model="form.fileType" placeholder="请选择">
                    <el-option label="工厂直通率" value="1"></el-option>
                    <el-option label="质量协议" value="2"></el-option>
                    <el-option label="RMA数据" value="3"></el-option>
                    <el-option label="出货检验报告" value="4"></el-option>
                    <el-option label="质量体系资质" value="5"></el-option>
                  </el-select>
              </el-form-item>
            </div>
            <div  class="col col4" >
                <el-form-item label="时间范围" prop="ScopeTime">
                    <el-date-picker  @change="dateChange" v-model="form.ScopeTime" type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
            </div>
            <div  class="col col8" >
                <el-form-item label="说明" prop="bz">
                    <el-input v-model="form.bz" ></el-input>
                </el-form-item>
            </div>
            <div class="col col8">
                <el-form-item label="附件" prop="fileList">
                    <el-upload
                        :action="actionURL"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :headers="tokenHeader"
                        :on-success="handleSuccess"
                        multiple
                        :file-list="form.fileList"
                    >
                        <el-button size="small" type="primary" plain>点击上传</el-button>
                    </el-upload>
                </el-form-item>
            </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { baseHost } from '@/assets/js/PublicData.js'
import Component from '@/assets/js/components.js'
import { fileHandle } from '@/assets/js/FileAction.js'
import { rules } from './js/setting.js'
import dayjs from 'dayjs'
export default {
  name: 'AddFileList',
  components: { ...Component },
  data: function () {
    return {
      form: { fileList: [] },
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      rules: rules,
      tokenHeader: { token: localStorage.getItem('token') }
    }
  },
  methods: {
    dateChange (date) {
      this.form.timeScopeStartTime = dayjs(date[0]).format('YYYY-MM-DD')
      this.form.timeScopeEndTime = dayjs(date[1]).format('YYYY-MM-DD')
    },
    handlePreview (file) {
      fileHandle.handlePreview(file)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.fileList.push(element)
        })
      }
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.fileList.length; i++) {
        if (this.form.fileList[i].id === file.id) {
          this.form.fileList.splice(i, 1)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0px 0px;
  }
}
</style>
